
import './App.css';
import NavBar from './Components/NavBar';
import Footer from './Components/Footer';
import { BrowserRouter, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Projects from './Pages/Projects';
import Teaching from './Pages/Teaching';
import Contact from './Pages/Contact'; 
import Experiments from './Pages/Experiments';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />

        <div className="app-body">
          <Route exact path="/">
            <Home />
          </Route>

          <Route exact path="/projects">
            <Projects />
          </Route>

          <Route exact path="/experiments">
            <Experiments />
          </Route>

          <Route exact path="/teaching">
            <Teaching />
          </Route>

          <Route exact path="/contact">
            <Contact />
          </Route>
        </div>


        <Footer />
      </BrowserRouter>
      </div>
  );
}

export default App;
