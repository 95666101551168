import React from 'react';
import LD_Logo from '../images/LD_Logo.png';
import { useHistory } from 'react-router-dom';

import '../styles/navbar.css';

export default function NavBar () {
  
  const history = useHistory();

  return (
    <div className="header">
      <button className="nav-logo" onClick={() => {history.push('/')}}>
          <img 
          alt="A black letter L with a dary gray letter D that overlap in the center."
          id="logo" 
          src={LD_Logo} />
          Luca Damasco
      </button>


      <div className="nav-buttons">
        <button className="nav-link" onClick={() => {history.push('/projects')}}>Projects</button>
        <button className="nav-link" onClick={() => {history.push('/experiments')}}>Experiments</button>
        <button className="nav-link" onClick={() => {history.push('/teaching')}}>Teaching</button>
        <button className="nav-link" onClick={() => {history.push('/contact')}}>Contact</button>
      </div>

    </div>
  )
};
