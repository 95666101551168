import React from 'react';
import ShowcaseBox from '../Components/ShowcaseBox';
import WickEditor from '../resources/previews/wickeditor.gif';
import KnowWhere from '../resources/previews/knowwheretogo.gif';
import py5 from '../resources/previews/py5.gif';
import GoSpendLocal from '../resources/previews/gospendlocal.gif';
import Finulator from '../resources/previews/finulator.gif';

let projects = [
  {
    name: "Wick Editor",
    yearStart: "2016",
    active: true,
    company: "Wicklets",
    roles: ["Co-founder", "Lead Engineer", "Curriculum Designer"],
    skills: ["Javascript", "React", "HTML", "CSS", "Curriculum Design", "UX Research"],
    description: "Wick Editor is a free and open source animation and game creation tool designed for beginners that works entirely in a web browser.",
    highlights: ["Serves 90,000 monthly users.", "Won over $150,000 in grants.", "Supported by Mozilla."],
    learnMore: "https://www.youtube.com/watch?v=2XDvRlJE-G4",
    tryIt: "https://www.wickeditor.com",
    awards: true,
    preview: WickEditor,
    alt: "A bouncing ball animation is drawn in the Wick Editor 2D animation program. The bouncing ball moves left to right and bounces once."
  },
  {
    name: "py5.js",
    yearStart: "2020",
    active: true,
    company: "Self-Directed",
    roles: ["Project Lead"],
    skills: ["Javascript", "Python", "P5.js"],
    description: "Combining Pyodide.js and P5.js to create a web based version of Processing.py, a creative coding library.", 
    highlights: ["Expanding accessibility of tooling.", "Supported by COSA and the STUDIO for Creative Inquiry"],
    awards: true,
    preview: py5,
    learnMore: "https://github.com/Luxapodular/Py5.js",
    alt: "The py5.js logo appears in a pointilist style created by yellow, blue and pink dots."
  },
  {
    name: "Finulator by Troutwood",
    yearStart: "2020",
    yearEnd: "2020",
    company: "Troutwood",
    roles: ["Project Lead", "Software Engineer", "Game Designer"],
    skills: ["Javascript", "React", "Redux", "P5.js"],
    description: "An accessible game designed to teach high school students the basics of personal finance to improve their financial futures.", 
    highlights: ["Conducted interviews with educators.", "Coordinated design sessions.", "Created a backend game engine and front end interface."], 
    preview: Finulator,
    tryIt: 'https://finulator.io',
    alt: "A character walks in place on the left side of the screen while two trees animate toward the center of the screen."
  },
  {
    name: "GoSpendLocal",
    yearStart: "2020",
    yearEnd: "2020",
    company: "SpendLocal LLC",
    roles: ["Project Lead", "Software Engineer"],
    skills: ["Javascript", "React", "Redux", "Firebase"],
    description: "A prototype web service designed to help small businesses connect with local non profits.", 
    highlights: ['Finalist in the Mozilla Builders "Fix the Internet" Global Hackathon',  "Developed in under 90 days."],
    awards: true,
    learnMore: 'https://www.goSpendLocal.com',
    preview: GoSpendLocal,
    alt: "A green house, dollar symbol, and coupon symbol animate into the screen in succession and then GoSpendLocal appears on screen."
  },
  {
    name: "Know Where to Go",
    yearStart: "2017",
    yearEnd: "2018",
    company: "Highmark Health",
    roles: ["Project Manager", "UX Engineer"], 
    skills: ["Team Management", "Javascript", "Game Design"],
    description: "Digital multimedia experiences centered around using digital and physical interactions to inform people on where to go for medical care.",
    highlights: ["Helped lead a team of 5 students.", "Conducted dozens of UX Research Sessions, including stakeholder interviews."], 
    website: "https://academy.cs.cmu.edu/",
    learnMore: "https://luxapodular.github.io/healthtech2018/index.html",
    preview: KnowWhere,
    alt: "A yellow blob-like character rides a bike across the screen and hits a pot hole triggering a pop up window to ask a question."
  },
  {
    name: "CMU CS Academy Early Curriculum",
    yearStart: "2018",
    yearEnd: "2019",
    company: "CMU Computer Science Academy",
    roles: ["Education UX Designer", "Outreach"], 
    skills: ["Javascript", "Python", "Teaching", "Curriculum Design"],
    description: "Helped design a learning platform and curriculum exercises for CMU CS Academy, a free high school computer science curriculum.",
    highlights: ["Conducted field research with students and educators.", "Designed curriculum for use on the platform.", "Helped set guidelines for educational content quality and learning outcomes."], 
    learnMore: "https://academy.cs.cmu.edu/",
  },
  {
    name: "Riot Editor - Select Tools",
    yearStart: "2016",
    yearEnd: "2017",
    company: "Riot Games",
    roles: ["Technical Artist Intern", "Programmer"],
    skills: ["Python", "UX Research"],
    description: "Designed new tooling for game designers and artists for Riot Editor, Riot Games' internal game development tooling.",
    highlights: ["Conducted UX Research with Game Designers and Artists", "Designed and Implemented updates to legacy tooling to improve developer experience."] 
  },
]

export default function Projects () {
  return (
    <div>
      <div className="page-title">
        <h2>Projects</h2>
        <p>A selection of highlighted solo and team projects.</p>
      </div>

      <div className="page-content">
        {projects.map((project, i) => {
          return (
            <ShowcaseBox 
            index={i}
            key={project.name}
            data={project} />
          )
        })}
      </div>
    </div>
  )
}