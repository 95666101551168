import React from 'react';
import { SocialIcon } from 'react-social-icons';

export default function SocialContainer () {
  return (
    <div className="social-container">

      <SocialIcon url="https://github.com/Luxapodular/" network='github' bgColor='transparent' fgColor='#293241' />
      <SocialIcon url="https://www.linkedin.com/in/luca-damasco/" network='linkedin' bgColor='transparent' fgColor='#293241' />
      <SocialIcon url="https://twitter.com/Lucapodular" network='twitter' bgColor='transparent' fgColor='#293241' />
      <SocialIcon url="https://www.youtube.com/channel/UCXUM4laL0jXCO4wJjY15xqg" network='youtube' bgColor='transparent' fgColor='#293241' />
      <SocialIcon url="mailto:luca.damasco@gmail.com" network='email' bgColor='transparent' fgColor='#293241' />

    </div>
  )
}