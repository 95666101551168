import React from 'react';
import ShowcaseBox from '../Components/ShowcaseBox';
import CMUSCS from '../resources/previews/cmuscs.png';
import CMUART from '../resources/previews/cmuart.png';
import NYU from '../resources/previews/nyu.png';

let teaching = [
  {
    name: "Creative Coding",
    roles: ["Adjunct Professor"],
    company: "New York University",
    team: "Integrated Digital Media",
    description: 'Teaching introductory programming and physical computing skills at the graduate and undergraduate level.',
    highlights: ['Teaching P5.js, a programming language for the visual arts.', 'Teaching Arduino Basics', 'Conducted in-person and online sessions.'],
    yearStart: "2019",
    active: true,
    preview: NYU,
    alt: "A purple background with a white rectangular border frames a flat image of a torch. The letters NYU appear below the torch."
  },
  {
    name: "Interactivity",
    roles: ["Adjunct Professor"],
    company: "Carnegie Mellon University",
    team: "School of Art",
    description: 'Taught the basics of programming, interactivity, and computer science to undergraduate students.',
    highlights: ['Used P5.js and Processing.', 'Taught basics of interactive practices.', 'Examined and critqued New Media artworks.'],
    yearStart: "2018",
    preview: CMUART,
    alt: "The text Carnegie Mellon University School of Art appears over a black background."
  },
  {
    name: "CS Pedagogy",
    roles: ["Teaching Assistant"],
    company: "Carnegie Mellon University",
    team: "Computer Science Department",
    description: 'Helped organize CS Pedagogy, which would eventually become CMU CS Academy.',
    highlights: ['Taught the basic principles of UX design to students.', 'Taught the basic principles of computer science instruction to students.'],
    yearStart: "2017",
    yearEnd: "2018",
    preview: CMUSCS,
    alt: "A stylized red dragon appears above the text Carnegie Mellon. A horizontal line appears below the text. Below the Horizontal line is the text School of Computer Science."
  },
  {
    name: "Fundamentals of Programming and Computer Science",
    roles: ["Teaching Assistant"],
    company: "Carnegie Mellon University",
    team: "Computer Science Department",
    description: "Conducted Recitations, grading, and assisted in student project development in Carnegie Mellon's introductory Computer Science course.",
    highlights: ['Taught introductory and intermediate python skills.', 'Introduced algorithm design and problem solving principles.', 'Conducted recitation with over 20 students.'],
    yearStart: "2016",
    preview: CMUSCS,
    alt: "A stylized red dragon appears above the text Carnegie Mellon. A horizontal line appears below the text. Below the Horizontal line is the text School of Computer Science."
  },
  {
    name: "Computation for Creative Practice",
    roles: ["Teaching Assistant"],
    company: "Carnegie Mellon University",
    team: "Computer Science Department",
    description: 'Was on the first TA staff for the course, assisted in assignment design and taught recitations.',
    highlights: ['Taught in Processing.py, a python based visual-arts focused language.', 'Worked with students from all artistic and humanities disciplines.'],
    yearStart: "2015",
    preview: CMUSCS,
    alt: "A stylized red dragon appears above the text Carnegie Mellon. A horizontal line appears below the text. Below the Horizontal line is the text School of Computer Science."
  },
]

export default function Teaching () {
  return (
    <div>
      <div className="page-title">
        <h2>Teaching</h2>
        <p>Courses I'm teaching, have taught, or assisted.</p>
      </div>

      <div className="page-content">
        {teaching.map(project => {
          return (
            <ShowcaseBox 
            key={project.name}
            data={project} />
          )
        })}
      </div>
    </div>
  )
}