import React, { useState } from "react";
import axios from "axios";
import '../styles/contact.css';
import { useSpring, animated } from 'react-spring';
import * as easings from 'd3-ease';
import SocialContainer from "../Components/SocialContainer";


export default function Contact () {

  const spring = useSpring({
    config: {
      duration: 1000,
      easing: easings.easeCubic,
    },
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    }
  })

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://formspree.io/f/xbjqrjjz",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Thanks! I'll get in touch soon!", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <div className="contact">
      <div className="page-title">
        <h2>Contact Me</h2>
        <p>Get in touch on social media or send me an email! </p>
      </div>
      <SocialContainer /> 
      <animated.div style={spring}>
        <form className="contact-form" onSubmit={handleOnSubmit}>
          <label htmlFor="email">Email</label>
          <input id="email" type="email" name="email" required />

          <label htmlFor="subject">Subject</label>
          <input id="subject" name="subject" required />

          <label htmlFor="message">Message</label>
          <textarea id="message" name="message"></textarea>
          <button type="submit" disabled={serverState.submitting}>
            Submit
          </button>
          {serverState.status && (
            <p className={!serverState.status.ok ? "status errorMsg" : "status"}>
              {serverState.status.msg}
            </p>
          )}
        </form>
      </animated.div>
    </div>
  );
}