import React from 'react';
import ShowcaseBox from '../Components/ShowcaseBox';
import Physical from '../resources/previews/physicalphysics.gif';
import Peek from '../resources/previews/peekaboo.gif';
import Liners from '../resources/previews/liners.gif';
import Seismograph from '../resources/previews/seismograph.gif';

let experiments = [
  {
    name: "Physical Physics Simulator",
    skills: ["Arduino", "Digital Fabrication", "Processing (Java)"],
    description: 'A physical version of the classic "Bouncing Ball" built with Arduino, Processing, and scraps.',
    learnMore: 'https://youtu.be/NNOydZ-8MUM',
    preview: Physical,
    alt: "A bright orange foam ball being controlled by a small micro controller and bouncing around a box."
  },
  {
    name: "Peek-A-Boo",
    skills: ["Java", "Processing", "Face Tracking"],
    description: "A particle simulation that mimics the user's face and grows when the user looks away or closes their eyes.",
    learnMore: 'https://youtu.be/5mkAipGfU18',
    preview: Peek,
    alt: "Luca moves his face on the left of the screen and a mirror image made of particles follows his movements on the right."
  },
  {
    name: "Liners",
    skills: ["Java", "Processing"],
    description: "Simple 1 line virtual creatures that react differently when 1, 10, and 1000 are present.", 
    learnMore: 'https://youtu.be/4wf-SFkBHWk',
    preview: Liners,
    alt: "Several lines hover in smooth curves around a window and follow a computer mouse."
  },
  {
    name: "Financial Seismograph",
    skills: ["Java", "Processing"],
    description: "A faux stock ticker that shakes the window like a seismograph as the theoretical value changes.",
    learnMore: 'https://youtu.be/cTBhuzaUiNg',
    preview: Seismograph,
    alt: "As a mouse moves across a window the window shakes up and down while a line draws in an increasingly erratic fashion."
  }
]

export default function Experiments () {
  return (
    <div>

      <div className="page-title">
        <h2>Experiments</h2>
        <p>Projects I've created that explore the intersection of art and technology.</p>
      </div>

      <div className="page-content">
        {experiments.map(project => {
          return (
            <ShowcaseBox 
            key={project.name}
            data={project} />
          )
        })}
      </div>
    </div>
  )
}