import React from 'react';
import { useHistory } from 'react-router-dom';
import '../styles/home.css';
import { useSpring, animated } from 'react-spring';
import * as easings from 'd3-ease';

export default function Home () {
  const history = useHistory();

  const fadeIn1 = useSpring({
    config: {
      duration: 1000,
      easing: easings.easeCubic
    }, 
    from: {
      opacity: 0, 
      marginTop: -100,
    }, 
    to: {
      opacity: 1,
      marginTop: 0,
    }
  });

  const fadeIn2 = useSpring({
    delay: 500,
    config: {
      duration: 1000,
    }, 
    from: {
      opacity: 0, 
    }, 
    to: {
      opacity: 1,
    }
  });

  const fadeIn3 = useSpring({
    delay: 1000,
    config: {
      duration: 1000,
    }, 
    from: {
      opacity: 0, 
    }, 
    to: {
      opacity: 1,
    }
  });


  return (
    <div className="home-body">

        <div className="home-text">
          <animated.div style={fadeIn1}>
            <h1 className="home-headline">Creating Tools that Empower and Inspire Artists</h1>
          </animated.div>

          <animated.div style={fadeIn2} className="home-subtext">
            I'm a software developer and educator that loves making free and accessible tools for creative people.
          </animated.div>
        </div>



      <animated.div style={fadeIn3} className="home-buttons">
        <button className="home-button" onClick={() => history.push('projects')}>
          Highlighted Projects
        </button>
        <button className="home-button secondary" onClick={() => history.push('contact')}>
          Resume and Contact
        </button>
      </animated.div>

    </div>
  )
}