import React from 'react';
import '../styles/showcasebox.css';
import { useSpring, animated } from 'react-spring';
import * as easings from 'd3-ease';

export default function ShowcaseBox (props) {

  const spring = useSpring({
    delay: props.index * 300,
    config: {
      duration: 1000,
      easing: easings.easeCubic,
    },
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    }
  })

  return (
    <animated.div style={spring} className="showcase-box">
        <div className="showcase-header">
          <h2>{props.data.name}</h2>
          { props.data.roles && 
            <div className="showcase-roles">
              <span className="showcase-role-text">
                {props.data.roles.map((role, i) => i > 0 ? ', ' + role: role)}
              </span>
            </div>
          }
        </div>

      <img className="showcase-image" src={props.data.preview} alt={props.data.alt}/>

      <div className="showcase-body">
        <div className="showcase-description">
          <p>{props.data.description}</p>
          <ul className="showcase-highlights">
            {props.data.highlights && props.data.highlights.map(hl => {
              return <li key={hl}>{hl}</li>
            })}
          </ul>
        </div>

        <div className="showcase-actions">
          {
            props.data.learnMore && 
            <button 
              onClick={() => window.open(props.data.learnMore)} 
              className="action-button learn-more">
                Learn More
            </button>
          }
          {
            props.data.tryIt && 
            <button 
              onClick={() => window.open(props.data.tryIt)} 
              className="action-button try-it">
              Try It
            </button>
          }
        </div>
      </div>
    </animated.div>
  )
}